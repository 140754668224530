import React, { useEffect, useState } from "react"
import { Checkbox } from "antd"
import {
  UserOutlined,
  MailOutlined,
  LinkOutlined,
  CreditCardOutlined,
  CalendarOutlined,
  CreditCardFilled,
  paymentError
} from "@ant-design/icons"
import { currencyToSymbol } from "../../../../data/managers/currencytosymbol"
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js"

const CheckoutInfo = props => {

  const stripe = useStripe()
  const elements = useElements()

  const [showUpSell, setShowUpSell] = useState(true)


  useEffect(() => {
    if (
      props.cartInfo &&
      props.cartInfo.cartItems &&
      props.cartInfo.cartItems[0].category === "soundcloud" &&
      props.cartInfo.cartItems[0].subCategory === "followers"
    ) {
      setShowUpSell(false)
    }
  })

  return (
    <div>
      <div className="text-gray-700">
        <div>
          {props.coverInfo && (
            <div className="mb-5">
              <div className="flex items-center">
                <div
                  style={{ borderWidth: "0px" }}
                  className="flex items-center w-full px-3 py-2 border-2 rounded-md border-gray-200 focus:outline-none focus:border-gray-500 transition-colors"
                >
                  <div>
                    <img src={props.coverInfo.url} className="w-16 h-16" />
                  </div>
                  <div className="ml-2">
                    <a href={props.targetUrl} target="_blank">
                      {props.coverInfo.title}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            style={{ borderWidth: "0px" }}
            className="w-full px-3 border-2 border-gray-200 rounded-md rounded-b-none"
          ></div>

          {showUpSell === true && (
            <div>
              {props.relatedProductsList &&
                props.relatedProductsList.map(category => (
                  <div key={category.categoryName}>
                    <div
                      style={{ borderWidth: "0px", textAlign: "left" }}
                      className="w-full px-4 py-2 border-b-2 border-l-2 border-r-2 border-gray-200 capitalize font-bold"
                    >
                      <b style={{ textTransform: "uppercase", letterSpacing: "1px" }}>EXTEND CAMPAIGN
                        - {category.categoryName}</b>
                    </div>
                    {category &&
                      category.relatedProducts &&
                      category.relatedProducts.map(product => (
                        <div
                          style={{ borderWidth: "1px" }}
                          key={product.id}
                          className={product.popular ? "w-full px-4 py-2 border-b-2 border-l-2 border-r-2 border-gray-200 capitalize flex justify-between orange-border" : "w-full px-4 py-2 border-b-2 border-l-2 border-r-2 border-gray-200 capitalize flex justify-between"}
                        >
                          <div>
                            <Checkbox
                              onChange={event =>
                                event.target.checked === true
                                  ? props.addItemToCart(product)
                                  : props.deleteItemFromCart(
                                    props.cartInfo.cartItems.find(
                                      item => item.sku === product.sku
                                    )
                                  )
                              }
                              checked={
                                props.cartInfo &&
                                props.cartInfo.cartItems.some(
                                  item => item.sku === product.sku
                                )
                              }
                            >
                              <span
                                style={{ letterSpacing: "0.5px" }}>Add {product.times} {product.subCategory}{" "}</span>
                              {product.popular && (
                                <span
                                  className="px-2 py-1 color-white rounded-full"
                                  style={{ fontSize: "11px", background: "#ff8e00", marginLeft: "5px" }}
                                >
                                  BEST VALUE
                                </span>
                              )}
                            </Checkbox>
                          </div>
                          <div>
                            {currencyToSymbol(product.currency)}
                            {product.amount}
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="text-gray-700">
        <div className="m-5">
          <h1 className="text-center color-home font-bold text-xl">
            <i className="fa fa-credit-card" style={{marginLeft: "5px"}}></i> Secure Checkout
          </h1>
        </div>
        <div
          style={{ borderWidth: "1px", borderBottom: "0px" }}
          className="text-left w-full px-3 py-2 border-t-2 border-l-2 border-r-2 border-gray-200 rounded-md rounded-b-none"
        >
          <MailOutlined style={{ verticalAlign: "0px" }} /> {props.userEmail}
        </div>
        <div
          style={{ borderWidth: "1px", borderBottom: "0px" }}
          className="text-left  w-full px-3 py-2 border-t-2 border-l-2 border-r-2 border-gray-200"
        >
          <UserOutlined style={{ verticalAlign: "0px" }} /> {props.userName}
        </div>
        <div
          style={{ borderWidth: "1px" }}
          className="text-left w-full px-3 py-2 border-2 border-gray-200 rounded-md rounded-t-none"
        >
          <LinkOutlined style={{ verticalAlign: "0px" }} /> {props.targetUrl}
        </div>

        <div style={{ marginTop: "20px", display: "none" }} id="card-container"></div>
        <div
          className="ant-alert ant-alert-error mt-10"
          style={{
            display: "none",
            marginTop: "15px"
          }}
        >
          {props.paymentError}
        </div>

        <div>
          <div className="block w-full mx-auto py-3 text-center text-white">
            <div className="" style={{ width: "100%", marginBottom:"20px", marginTop:"20px" }}>
              <div className="cardBlock">
                <label style={{ fontSize: "14px" }} className="cardSpan">CARD NUMBER</label>
                <CardNumberElement
                  className="input "
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        border: "1px solid",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }} />
              </div>
              <div className="half cardBlock">
                <label style={{ fontSize: "14px" }}  className="cardSpan">EXPIRATION</label>
                <CardExpiryElement
                  className="input"

                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              </div>
              <div className="half ml2 cardBlock">
                <label style={{ fontSize: "14px" }}  className="cardSpan">CVC</label>
                <CardCvcElement
                  className="input"
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              </div>
              <small style={{ color: 'gray', fontSize: '10px', marginBottom: '10px', textAlign: "left" }}>
                *CVV number is a 3 digit numeric code behind the card
              </small>
            </div>

            {props.stripeErrorMessage && (
              <div className="alert alert-warn">
                {props.stripeErrorMessage}
              </div>
            )}


            {props.loading === true ? (
              <button className="rounded-full py-1 px-8 loading-button bg-gray-200 w-full">
                {!props.loading
                  ? ` Pay
                ${
                    props.cartInfo
                      ? currencyToSymbol(props.cartInfo.currency)
                      : ""
                  }${props.cartInfo ? props.cartInfo.totalAmount : ""}`
                  : "Processing..."}
              </button>
            ) : (
              <button
                className="rounded-full py-1 px-8 background-green checkout-button w-full"
                onClick={() =>
                  props.createNewOrder()
                }
              >
                Pay{" "}
                {props.cartInfo
                  ? currencyToSymbol(props.cartInfo.currency)
                  : ""}
                {props.cartInfo ? props.cartInfo.totalAmount : ""}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutInfo
