/* eslint-disable no-useless-escape */
export const currencyToSymbol = currency => {
  if (currency.toLowerCase() === "usd") {
    return "$"
  }
  if (currency.toLowerCase() === "eur") {
    return "€"
  }
  return currency
}
